<template>
  <el-dialog
    :title="type === 'create' ? '创建角色' : '编辑'"
    :visible.sync="visible"
    :modal-append-to-body="false"
    :before-close="cancel"
    width="600px"
  >
    <field label="角色昵称：" :asterisk="true" labelWidth="81px" :validation="validation.roleName"   >
      <el-input v-model="formData.roleName" placeholder="请输入角色昵称"></el-input>
    </field>
    <field label="角色描述：" :asterisk="true" labelWidth="81px" :validation="validation.des" >
      <el-input v-model="formData.des" placeholder="请输入角色描述"></el-input>
    </field>
    <field label="角色权限：" :asterisk="true" labelWidth="81px" :validation="validation.id" >
      <el-cascader v-model="selectedList" :options="roleList" :props="{ multiple: true, label: 'codeName', value: 'id', children: 'childrenList' }"></el-cascader>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as R from 'ramda'
const init = {
  roleName: '',
  des: '',
  id: null
}

export default {
  naem: 'editRole',
  props: {
    type: {
      type: String,
      default: 'create'
    },
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    roleList: {
      type: Array,
      default () {
        return []
      }
    },
    roleSelected: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      formData: R.clone(init),
      selectedList: []
    }
  },
  mixins: [validation({
    rules: {
      roleName: [v.required('请输入角色昵称')],
      des: [v.required('请输入角色描述')]
    },
    field: 'formData'
  })],
  methods: {
    cancel () {
      this.$emit('onCancel')
      this.selectedList = []
      this.formData = R.clone(init)
    },
    async confirm () {
      await this.$validate()
      const ids = this.selectedList.reduce((pre, cur) => {
        return pre.concat(cur)
      }, [])
      if (this.type === 'create') {
        this.$emit('onConfirmCreate', R.pick(['roleName', 'des'], this.formData))
      } else {
        this.$emit('onConfirmEdit', this.formData)
        this.$emit('onConfirmRole', { id: this.formData.id, authorityIds: ids })
      }
      this.selectedList = []
      this.formData = R.clone(init)
    }
  },
  watch: {
    data: {
      deep: true,
      handler (curVal, oldVal) {
        this.formData = {
          roleName: curVal.roleName || '',
          des: curVal.des || '',
          id: curVal.id || null
        }
      }
    },
    roleSelected: {
      handler (arr) {
        const list = []
        this.roleList.forEach(item => {
          if (arr.includes(item.id)) {
            if (Array.isArray(item.childrenList) && item.childrenList.length > 0) {
              item.childrenList.forEach(child => {
                if (arr.includes(child.id)) {
                  list.push([item.id, child.id])
                }
              })
            } else {
              list.push([item.id])
            }
          }
        })
        this.selectedList = list
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

</style>
