<template>
  <div class="container">

    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
      </div>
      <el-table ref="multipleTable" :data="roles">
        <el-table-column
          prop="roleName"
          label="昵称"
        >
        </el-table-column>
        <el-table-column
          prop="des"
          label="角色描述"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建日期"
        >
          <template slot-scope="scope">
            {{ fmtDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.id !== 1" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.id !== 1" type="text" @click="deleteOne(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EditContent
      :type="type"
      :data="selectData"
      :roleList="roleList"
      :roleSelected="roleSelected"
      :visible="showEdit"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmRole="handleConfirmRole"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="showEdit = false"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'
import EditContent from './edit'
import * as roleService from '../../api/role'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      type: 'create',
      roles: [],
      roleList: [],
      roleSelected: [],
      showEdit: false,
      selectData: null
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const res = await roleService.listNoPage()
        this.roles = res
      } catch (err) {
        this.$notify.error('获取列表失败！')
      }
    },
    async fetchAuthority (id) {
      try {
        const res = await roleService.authority(id)
        this.roleList = res.authorityList
        this.roleSelected = res.selected
      } catch (err) {
        this.$notify.error('获取角色失败！')
      }
    },
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    handleCreate () {
      this.type = 'create'
      this.selectData = null
      this.showEdit = true
    },
    edit (data) {
      this.type = 'edit'
      this.selectData = R.clone(data)
      this.fetchAuthority(data.id)
      this.showEdit = true
    },
    async deleteOne (id) {
      try {
        await roleService.deleteById(id)
        this.$notify.success({ title: '删除角色成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '删除发生错误' })
      }
    },
    async handleConfirmEdit (data) {
      try {
        await roleService.update(data)
        this.showEdit = false
        this.fetchData()
        this.$notify.success({ title: '编辑角色成功' })
      } catch (err) {
        this.$notify.error({ title: '编辑角色失败' })
      }
    },
    async handleConfirmRole (data) {
      try {
        await roleService.updateAuthority(data)
      } catch (error) {
        this.$notify.error({ title: '编辑权限失败' })
      }
    },
    async handleConfirmCreate (data) {
      try {
        await roleService.create(data)
        this.showEdit = false
        this.$notify.success('创建角色成功')
        this.fetchData()
      } catch (err) {
        this.$notify.error('创建角色失败')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

</style>
